import React from "react";
import { Navbar, PageHeader, ImprintBlockInfo } from "../../components";
import {
  navBarParam,
  header,
  imprint_1,
  imprint_2,
  imprint_3,
  imprint_4,
  imprint_5,
  imprint_6,
  imprint_7,
  imprint_8,
  imprint_9,
} from "./Data";

function Notice() {
  return (
    <>
      <Navbar {...navBarParam} />
      <PageHeader {...header}></PageHeader>
      <ImprintBlockInfo {...imprint_1} />
      <ImprintBlockInfo {...imprint_2} />
      <ImprintBlockInfo {...imprint_4} />
      <ImprintBlockInfo {...imprint_5} />
      <ImprintBlockInfo {...imprint_6} />
      <ImprintBlockInfo {...imprint_7} />
      <ImprintBlockInfo {...imprint_8} />
      <ImprintBlockInfo {...imprint_9} />

      <div style={{ marginBottom: "80px" }}></div>
    </>
  );
}

export default Notice;
