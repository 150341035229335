import React from "react";
import { navBarParam, header } from "./Data";
import { Navbar, ContactInfo, PageHeader, LeafletMap } from "../../components";

function Contact() {
  return (
    <>
      <Navbar {...navBarParam} />
      <PageHeader {...header}></PageHeader>
      <LeafletMap></LeafletMap>
      <ContactInfo></ContactInfo>
    </>
  );
}

export default Contact;
