// Image Gallery People
import bannerImage from "../../images/business/Klamberg/03_Business-Portrait-EschweilerPhotography_21A0022.jpg";

import ig1 from "../../images/business/Klamberg/01_Business-Portrait-EschweilerPhotography_21A9561.jpg";
import ig2 from "../../images/business/Klamberg/02_Business-Portrait-EschweilerPhotography_21A9848_1.jpg";
import ig3 from "../../images/business/Klamberg/04_Business-Portrait-EschweilerPhotography_21A9969 (Kopie).jpg";
import ig4 from "../../images/business/Klamberg/05_Business-Portrait-EschweilerPhotography_21A9937_1.jpg";
import ig5 from "../../images/business/Klamberg/06_Business-Portrait-EschweilerPhotography_21A0047 Kopie.jpg";

export const SubPortfolioGallery = {
  bannerImage: bannerImage,
  bannerHeading: "Klamberg",
  subPortfolioGalleryImages: [
    {
      imgScr: ig1,
    },
    {
      imgScr: ig2,
    },
    {
      imgScr: ig3,
    },
    {
      imgScr: ig4,
    },
    {
      imgScr: ig5,
    }
  ],
};
