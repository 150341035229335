import welcomeImage_1_full from "../../images/welcomeImageSlider/welcomeImage_1_PROD.jpg";
import welcomeImage_2_full from "../../images/welcomeImageSlider/welcomeImage_2_PROD.jpg";

// Image Gallery People
import Architecture_and_Interior from "../../images/ImageGallery/Architecture_and_Interior.jpg";
import DTM_Scherer_SE from "../../images/ImageGallery/DTM_Scherer_SE.jpg";
import Rowe_Oil from "../../images/ImageGallery/Rowe_Oil.jpg";
import Plan_at_Move from "../../images/ImageGallery/Plan_at_Move.jpg";
import Klamberg from "../../images/ImageGallery/Klamberg.jpg";
import Commercials_and_Packshots from "../../images/ImageGallery/Commercials_and_Packshots.jpg";

export const navBarParam = {
  transparent: true,
};

export const welcomeImageSlider = {
  heading: "ESCHWEILER PHOTOGRAPHY",
  welcomeImages: [
    { full: welcomeImage_1_full, small: '' },
    { full: welcomeImage_2_full, small: '' },
  ],
};

export const imageCategoryGallery = {
  imageCategoryGallery: [
    {
      name: " ",
      images: [
        {
          imgSrc: Architecture_and_Interior,
          desc: "Architecture & Interior",
          param: "Architecture_and_Interior",
        },
        {
          imgSrc: DTM_Scherer_SE,
          desc: "DTM Scherer SE",
          param: "DTM_Scherer_SE",
        },
        {
          imgSrc: Rowe_Oil,
          desc: "Rowe Oil",
          param: "Rowe_Oil",
        },
        {
          imgSrc: Plan_at_Move,
          desc: "Plan @ Move",
          param: "Plan_at_Move",
        },
        {
          imgSrc: Klamberg,
          desc: "Klamberg",
          param: "Klamberg",
        },
        {
          imgSrc: Commercials_and_Packshots,
          desc: "Commercials & Packshots",
          param: "Commercials_and_Packshots",
        },
      ],
    },

   /**Hier können mehrere Kategorien nach dem Beipsiel oben als Array eingefühgt werden. */
  ],
};
