import React from "react";
import "./AboutMe.css";

function AboutMe({ shortDesc, longDesc }) {
  return (
    <div className="Container">
      <div className="about-wrapper">
        <div className="leftColumn30">
          <h2>About Me</h2>
          <div className="styledDividerLeft"></div>
          <div className="about-short-desc">{shortDesc}</div>
        </div>
        <div className="rightColumn70">
          <div className="about-long-desc">{longDesc}</div>
        </div>
      </div>
    </div>
  );
}

export default AboutMe;
