import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { Container } from "../../globalStyles";

export const Nav = styled.nav`
  background-color: ${({ transparent }) =>
    transparent ? "transparent" : "white"};
  height: 62px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  border-bottom: 2px solid rgba(256, 256, 256, 0.3);
  ${({ active }) =>
    active &&
    `
    background: white;
  `}
`;

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 62px;
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1000px) {
    color: rgba(74, 74, 76, 1);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    -webkit-transform: translate(-100%, 60%);
    -moz-transform: translate(-100%, 60%);
    -o-transform: translate(-100%, 60%);
    font-size: 25px;
    cursor: pointer;
  }
`;

export const MobileHeaderDesc = styled.div`
  display: none;
  @media screen and (max-width: 1000px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding-left: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-weight: bold;
    color: rgba(74, 74, 76, 1);
    padding-right: 100px;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 62px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 0.85;
    transition: all 0.5s ease-in-out;
    background: white;
  }
`;

export const NavItem = styled.li`
  height: 30px;
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: ${({ active, transparent }) =>
      (active == "active") & (transparent == true)
        ? "1px solid rgba(74, 74, 76, 1)"
        : "1px solid rgba(74, 74, 76, 1)"};
    border-bottom: ${({ transparent }) =>
      !transparent ? "1px solid rgba(74, 74, 76, 1)" : ""};
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    padding: 30px 0px 30px 0px;
    &:hover {
      border: none;
    }
  }
`;

export const NavLink = styled(Link)`
  color: ${({ active, transparent }) =>
    (active == "active") & (transparent == true)
      ? "rgba(74, 74, 76, 1)"
      : "rgba(74, 74, 76, 1)"};
  color: ${({ transparent }) => (!transparent ? "rgba(74, 74, 76, 1)" : "")};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  &:hover {
    color: ${({ active, transparent }) =>
      (active == "active") & (transparent == true)
        ? "rgba(74, 74, 76, 1)"
        : "rgba(74, 74, 76, 1)"};
    color: ${({ transparent }) => (!transparent ? "rgba(74, 74, 76, 1)" : "")};
  }
  @media screen and (max-width: 1000px) {
    text-align: center;
    width: 100%;
    display: table;
    color: rgba(74, 74, 76, 1);
    font-size: 16px;
    font-weight: bold;
    &.active {
      color: black;
      font-weight: bolder;
    }
  }
  &.active {
    font-weight: bold;
  }
`;
