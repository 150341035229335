import aboutImage from "../../images/AboutImage.JPG";

export const navBarParam = {
  transparent: false,
};
 
export const AboutImageSrc = {
  imageSrc: aboutImage,
};

export const SimpleHeaderParam = {
  simpleHeaderText: "Christian Eschweiler",
};

export const AboutMeParam = {
  shortDesc:
    "Hey there, it's your boy Christian!",
  longDesc:
    "A lot of people call me „Esche“. Not because I'm some leafy creature, mind you, but because I'm a rockstar photographer with a passion for rock and roll. When I'm not behind the lens, you can find me on stage with my band, belting out some of our favorite tunes. So, whether you're looking for some killer shots or just want to say hi, feel free to hit me up. If you need some headshots for your business, or just want to capture a special moment in perfect light shaping, I'm your guy.",
};
