import React from "react";
import { Container } from "../../globalStyles";
import "./ContactInfo.css";
import place from "../../images/place.svg";
import mail from "../../images/mail.svg";
import phone from "../../images/phone.svg";

function ContactInfo({}) {
  return (
    <>
      <Container>
        <div className="contact-wrapper">
          <div className="contact-container">
            <div className="icons">
              <img src={place} alt="Avatar" class="avatar"></img>
            </div>
            <div className="text-center">
              <div className="praefix icons">Studio</div>
              <div className="icons desc">Hüxtertorallee 23564 Lübeck</div>
            </div>
          </div>
          <div className="contact-container">
            <div className="icons">
              <img src={phone} alt="Avatar" class="avatar"></img>
            </div>
            <div className="text-center">
              <div className="praefix icons">Represented by Dragon Productions</div>
              <div className="icons desc">+49 177 7381037</div>
            </div>
          </div>
          <div className="contact-container">
            <div className="icons">
              <img src={mail} alt="Avatar" class="avatar"></img>
            </div>
            <div className="text-center">
              <div className="praefix icons">Email</div>
              <div className="icons desc">info@eschweilerphotography.com</div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default ContactInfo;
