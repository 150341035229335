// Image Gallery People
import bannerImage from "../../images/business/Plan_at_Move/04_Business-PortraitArchitecture-InteriorEschweilerPhotography_M1A6886.jpg";

import ig1 from "../../images/business/Plan_at_Move/01_Business-PortraitArchitecture-InteriorEschweilerPhotography_M1A6708.jpg";
import ig2 from "../../images/business/Plan_at_Move/02_Business-PortraitArchitecture-InteriorEschweilerPhotography_M1A7638.jpg";
import ig3 from "../../images/business/Plan_at_Move/03_Business-PortraitArchitecture-Interior-EschweilerPhotography__M1A7320.jpg";
import ig4 from "../../images/business/Plan_at_Move/05_Business-PortraitArchitecture-InteriorEschweilerPhotography_M1A6854.jpg";
import ig5 from "../../images/business/Plan_at_Move/06_Business-PortraitArchitecture-InteriorEschweilerPhotography_M1A7277.jpg";
import ig6 from "../../images/business/Plan_at_Move/07_Business-PortraitArchitecture-InteriorEschweilerPhotography_M1A7661.jpg";

export const SubPortfolioGallery = {
  bannerImage: bannerImage,
  bannerHeading: "Plan @ Move",
  subPortfolioGalleryImages: [
    {
      imgScr: ig1,
    },
    {
      imgScr: ig2,
    },
    {
      imgScr: ig3,
    },
    {
      imgScr: ig4,
    },
    {
      imgScr: ig5,
    },
    {
      imgScr: ig6,
    }
  ],
};
