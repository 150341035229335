import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";
import "./PortfolioNavBar.css";

function PortfolioNavBar() {
  const [expandHeader, setExpandHeader] = useState(false);
  const [backgroundColor, setbackgroundColor] = useState("rgb(255,255,255, 0)");

  window.addEventListener("resize", () => {
    changeBackground();
  });
  window.addEventListener("scroll", changeBackground);
  window.addEventListener("click", function (event) {
    if (window.scrollY <= 5) {
      setbackgroundColor("transparent");
    }
  });

  useEffect(() => {
    const divContainer = document.querySelector(
      "#PortfolioNavBar_FADE_TRIGGER"
    );

    // IF NOTEBOOK OR DESKTOP PC
    if (window.orientation !== "undefined") {
      divContainer.addEventListener("mouseenter", () => {
        setExpandHeader(true);
      });

      divContainer.addEventListener("mouseleave", () => {
        setExpandHeader(false);
      });
    } else {
      divContainer.addEventListener("click", () => {
        var newExpandHeader = !expandHeader;
        setExpandHeader(newExpandHeader);
      });
    }
  });

  function changeBackground() {
    let hiddenPercentage = window.scrollY / window.innerHeight;
    let innerWidth = window.innerWidth;
    if (hiddenPercentage > 0.9 || innerWidth <= 1000) {
      setbackgroundColor("rgb(255,255,255, 1)");
    } else {
      setbackgroundColor("rgb(255,255,255, 0)");
    }
  }

  return (
    <div
      className="portfolio-navbar-container"
      id="PortfolioNavBar_FADE_TRIGGER"
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={
          expandHeader
            ? "portfolio-navbar-toggle-top whiteBG hidden"
            : "portfolio-navbar-toggle-top visible"
        }
      >
        <div className="portfolio-navbar-top-placeholder"></div>
        <div className="portfolio-navbar-top-placeholder"></div>
        <div className="portfolio-navbar-top"></div>
        <div className="portfolio-navbar-top"></div>
      </div>
      <div
        className={
          expandHeader
            ? "portfolio-navbar-top-placeholder whiteBG"
            : "portfolio-navbar-top-placeholder"
        }
      ></div>
      <div
        className={
          expandHeader
            ? "portfolio-navbar-top-placeholder whiteBG"
            : "portfolio-navbar-top-placeholder"
        }
      ></div>
      <h3
        className={
          expandHeader
            ? "portfolio-navbar-name whiteBG"
            : "portfolio-navbar-name"
        }
      >
        ESCHWEILER PHOTOGRAPHY
      </h3>
      <div
        className={
          expandHeader
            ? "portfolio-navbar-top-placeholder whiteBG"
            : "portfolio-navbar-top-placeholder"
        }
      ></div>
      <div
        className={
          expandHeader
            ? "portfolio-navbar-top-placeholder whiteBG"
            : "portfolio-navbar-top-placeholder"
        }
      ></div>
      <div
        className={
          expandHeader
            ? "portfolio-navbar-more whiteBG visible"
            : "portfolio-navbar-more hidden"
        }
      >
        <div className="portfolio-navbar-divider"></div>
        <div className="portfolio-navbar-top-placeholder"></div>
        <div className="portfolio-navbar-top-placeholder"></div>
        <ul className="portfolio-navbar-list-inline">
          <li className="portfolio-navbar-list-inline-item">
            <a href="/portfolio">PORTFOLIO</a>
          </li>
          <li className="portfolio-navbar-list-inline-item">
            <a href="/about">ABOUT</a>
          </li>
          <li className="portfolio-navbar-list-inline-item">
            <a href="/contact">CONTACT</a>
          </li>
        </ul>
        <div className="portfolio-navbar-top-placeholder"></div>
      </div>
    </div>
  );
}

export default PortfolioNavBar;
