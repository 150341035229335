import React, { useEffect, useState } from "react";
import "./SimpleHeader.css";

function SimpleHeader({ simpleHeaderText }) {
  return (
    <div className="simple-header-area">
      <h2 className="simpleHeader marginBottom">{simpleHeaderText}</h2>
    </div>
  );
}

export default SimpleHeader;
