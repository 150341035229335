// Image Gallery People
import bannerImage from "../../images/business/DTM_Scherer_SE/13_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A3646.jpg";

import ig1 from "../../images/business/DTM_Scherer_SE/01_SchererGruppe-DTM-Documentary-EschweilerPhotography_M1A3490.jpg";
import ig2 from "../../images/business/DTM_Scherer_SE/02_SchererGruppe-DTM-Documentary-EschweilerPhotography_M1A2717.jpg";
import ig3 from "../../images/business/DTM_Scherer_SE/05_SchererGruppe-DTM-Documentary-EschweilerPhotography_M1A3695.jpg";
import ig4 from "../../images/business/DTM_Scherer_SE/09_SchererGruppe-DTM-Documentary-EschweilerPhotography_M1A2860.jpg";
import ig5 from "../../images/business/DTM_Scherer_SE/10_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A3629.jpg";
import ig6 from "../../images/business/DTM_Scherer_SE/11_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A3616.jpg";
import ig7 from "../../images/business/DTM_Scherer_SE/12_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A3607.jpg";
import ig8 from "../../images/business/DTM_Scherer_SE/14_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A3684.jpg";
import ig9 from "../../images/business/DTM_Scherer_SE/15_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A3733.jpg";
import ig10 from "../../images/business/DTM_Scherer_SE/17_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A4009.jpg";
import ig11 from "../../images/business/DTM_Scherer_SE/19_SchererGruppe-DTM-Documentary-EschweilerPhotography_M1A3277.jpg";
import ig12 from "../../images/business/DTM_Scherer_SE/20_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A4577.jpg";
import ig13 from "../../images/business/DTM_Scherer_SE/21_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A5330.jpg";
import ig14 from "../../images/business/DTM_Scherer_SE/22_SchererGruppe-DTM-Documentary-EschweilerPhotography_M1A2926.jpg";
import ig15 from "../../images/business/DTM_Scherer_SE/24_SchererGruppe-DTM-Documentary-EschweilerPhotography_M1A3066.jpg";
import ig16 from "../../images/business/DTM_Scherer_SE/26_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A3875.jpg";
import ig17 from "../../images/business/DTM_Scherer_SE/27_SchererGruppe-DTM-Documentary-EschweilerPhotography_M1A3096.jpg";
import ig18 from "../../images/business/DTM_Scherer_SE/30_SchererGruppe-DTM-Documentary-EschweilerPhotography_M1A3135.jpg";
import ig19 from "../../images/business/DTM_Scherer_SE/32_SchererGruppe-DTM-Documentary-EschweilerPhotography_21A3907.jpg";

export const SubPortfolioGallery = {
  bannerImage: bannerImage,
  bannerHeading: "DTM Scherer SE",
  subPortfolioGalleryImages: [
    {
      imgScr: ig1,
    },
    {
      imgScr: ig2,
    },
    {
      imgScr: ig3,
    },
    {
      imgScr: ig4,
    },
    {
      imgScr: ig5,
    },
    {
      imgScr: ig6,
    },
    {
      imgScr: ig7,
    },
    {
      imgScr: ig8,
    },
    {
      imgScr: ig9,
    },
    {
      imgScr: ig10,
    },
    {
      imgScr: ig11,
    },
    {
      imgScr: ig12,
    },
    {
      imgScr: ig13,
    },
    {
      imgScr: ig14,
    },
    {
      imgScr: ig15,
    },
    {
      imgScr: ig16,
    },
    {
      imgScr: ig17,
    },
    {
      imgScr: ig18,
    },
    {
      imgScr: ig19,
    }
  ],
};
