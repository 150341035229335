import React, { useEffect, useState } from "react";
import "./FullScreenImageSlider.css";
import { ScrollIconAnimation } from "../../components";

function FullScreenImageSlider({ heading, welcomeImages }) {
  const [initLoaded, setInitLoaded] = useState(true);
  const [mobileDisplay, setMobileDisplay] = useState(true);

  var slideIndex = 1;

  useEffect(() => {
    showSlides(slideIndex);
    updateMobileHeaderSpace();
  }, []);

  window.addEventListener("resize", () => {
    updateMobileHeaderSpace();
  });

  function plusSlides(n) {
    showSlides((slideIndex += n));
    setInitLoaded(false);
  }

  function currentSlide(n) {
    showSlides((slideIndex = n));
  }

  function showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    if (n > slides.length) {
      slideIndex = 1;
    }
    if (n < 1) {
      slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[slideIndex - 1].style.display = "flex";
  }

  function updateMobileHeaderSpace() {
    let windowWidth = window.innerWidth;
    if (windowWidth <= 1000) {
      setMobileDisplay(true);
    } else {
      setMobileDisplay(false);
    }
  }

  return (
    <div>
      <div className={mobileDisplay ? "mobileHeaderSpaceHome" : ""}></div>
      <div class="slideshow-container">
        {welcomeImages.map((item, index) => {
          return (
            <div class="mySlides fade" key={index}>

                  <img
                    src={item.full}
                    className="slideImage imageZoomInEffect"
                    alt="image"
                  />

              <h1
                className={
                  "welcomeText roboto-font " +
                  (initLoaded ? "fontAppearEffect" : "")
                }
                style={{ display: mobileDisplay ? "none" : "block" }}
              >
                {heading}
              </h1>
              <a class="prev" onClick={() => plusSlides(-1)}>
                &#10094;
              </a>
              <a class="next" onClick={() => plusSlides(1)}>
                &#10095;
              </a>
              <ScrollIconAnimation></ScrollIconAnimation>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FullScreenImageSlider;
