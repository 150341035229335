import React from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "../../globalStyles";
import {
  TextWrapper,
  Heading,
  Subtitle,
  InfoSec,
  Horizontal,
} from "./PageHeader.elements";

function InfoSection({ headline, subheadline }) {
  return (
    <>
      <InfoSec>
        <Container>
          <TextWrapper>
            <Heading>{headline}</Heading>
            <Horizontal />
            <Subtitle>{subheadline}</Subtitle>
          </TextWrapper>
        </Container>
      </InfoSec>
    </>
  );
}

export default InfoSection;
