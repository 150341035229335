import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { LazyLoadPlaceholder } from "../../components";
import {
  PortfolioNavBar,
  FullScreenPortfolioImage,
  SubPortfolioGallery,
  ImageGallery,
  ImageGalleryFilter,
} from "../../components";
import { imageCategoryGallery } from "../PortfolioGallery/Data";

function GetPortfolio() {
  const history = useHistory();
  const location = useLocation();
  let subcat;
  const [subPortfolioGalleryObject, setSubPortfolioGalleryObject] = useState();

  useEffect(() => {
    // get URL parameter
    subcat = new URLSearchParams(location.search).get("subcat");
    try {
      require(`${"./" + subcat}`);
    } catch (error) {
      history.push("/portfolio");
    }
    import("./" + subcat).then((SubPortfolioGallery) => {
      setSubPortfolioGalleryObject(SubPortfolioGallery.SubPortfolioGallery);
    });
  }, []);

  return (
    <>
      {subPortfolioGalleryObject && (
        <>
          <PortfolioNavBar />
          <FullScreenPortfolioImage
            {...{
              heading: subPortfolioGalleryObject["bannerHeading"],
              imageSrc: subPortfolioGalleryObject["bannerImage"],
            }}
          ></FullScreenPortfolioImage>
          <div className="Container">
            <SubPortfolioGallery
              {...subPortfolioGalleryObject}
            ></SubPortfolioGallery>
          </div>
          <LazyLoad
            key="1"
            placeholder={<LazyLoadPlaceholder />}
            once
            offset={0}
          >
            <ImageGalleryFilter {...imageCategoryGallery} />
            <ImageGallery {...imageCategoryGallery} />
          </LazyLoad>
        </>
      )}
    </>
  );
}

export default GetPortfolio;
