import React, { useEffect, useState } from "react";
import "./FullScreenPortfolioImage.css";
import { ScrollIconAnimation } from "../../components";

function FullScreenPortfolioImage({ heading, imageSrc }) {
  const [displayOpacity, steDisplayOpacity] = useState(1);
  const [mobileDisplay, setMobileDisplay] = useState(true);
  var innerHeight;

  useEffect(() => {
    setInnerHeight();
    updateMobileHeaderSpace();
  });

  window.addEventListener("resize", () => {
    setInnerHeight();
    updateMobileHeaderSpace();
  });
  window.addEventListener("scroll", changeBackground);

  function changeBackground() {
    let hiddenPercentage = window.scrollY / innerHeight;
    if (hiddenPercentage <= 0.1999) {
      steDisplayOpacity(1);
    } else if (hiddenPercentage >= 0.9) {
      steDisplayOpacity(0);
    } else if (hiddenPercentage >= 0.8) {
      steDisplayOpacity(0);
    } else if (hiddenPercentage >= 0.7) {
      steDisplayOpacity(0.3);
    } else if (hiddenPercentage >= 0.6) {
      steDisplayOpacity(0.4);
    } else if (hiddenPercentage >= 0.5) {
      steDisplayOpacity(0.5);
    } else if (hiddenPercentage >= 0.4) {
      steDisplayOpacity(0.6);
    } else if (hiddenPercentage >= 0.3) {
      steDisplayOpacity(0.7);
    } else if (hiddenPercentage >= 0.2) {
      steDisplayOpacity(0.85);
    }
  }

  function updateMobileHeaderSpace() {
    let windowWidth = window.innerWidth;
    if (windowWidth <= 1000) {
      setMobileDisplay(true);
    } else {
      setMobileDisplay(false);
    }
  }

  function setInnerHeight() {
    innerHeight = window.innerHeight;
  }

  return (
    <>
      <div className={mobileDisplay ? "mobileHeaderSpace" : ""}></div>
      <div
        className="portfolio-image-wrapper"
        style={{ opacity: displayOpacity }}
      >
        <img className="portfolio-image imageZoomInEffect" src={imageSrc}></img>
        <h1
          className="welcomeText roboto-font fontAppearEffect"
          style={{ display: mobileDisplay ? "none" : "block" }}
        >
          {heading}
        </h1>
        <ScrollIconAnimation></ScrollIconAnimation>
      </div>
      <div
        className="simple-header-area"
        style={{ display: mobileDisplay ? "block" : "none" }}
      >
        <h2>{heading}</h2>
      </div>
    </>
  );
}

export default FullScreenPortfolioImage;
