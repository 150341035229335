import React from "react";
import { welcomeImageSlider, navBarParam, imageCategoryGallery } from "./Data";
import {
  Navbar,
  FullScreenImageSlider,
  ImageGallery,
  ImageGalleryFilter,
} from "../../components";

function PortfolioGallery() {
  return (
    <>
      <Navbar {...navBarParam} />
      <FullScreenImageSlider {...welcomeImageSlider} />
      <ImageGalleryFilter {...imageCategoryGallery} />
      <ImageGallery {...imageCategoryGallery} />
    </>
  );
}

export default PortfolioGallery;
