import styled from "styled-components";

export const InfoSec = styled.div`
  color: rgba(74, 74, 76, 1);
  padding: 90px 0;
  background: white;
`;

export const TextWrapper = styled.div`
  padding-top: 60px;
  max-width: 653px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600;
  color: rgba(74, 74, 76, 1);
`;

export const Subtitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: gray;
`;

export const Horizontal = styled.div`
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid #ccc;
  border-radius: 50px;
  margin: 1em 0;
  padding: 0;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
`;
