import React from "react";
import GlobalStyle from "./globalStyles";
import "./globalStyles.css";
import PortfolioGallery from "./pages/PortfolioGallery/PortfolioGallery";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Notice from "./pages/Notice/Notice";
import GetPortfolio from "./pages/GetPortfolio/GetPortfolio";
import {
  Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Footer, ScrollToTop } from "./components";
import createBrowserHistory from 'history/createBrowserHistory';

const history = createBrowserHistory({forceRefresh:true});

function App() {
  return (
    <Router history={history}>
      <GlobalStyle />
      <ScrollToTop />
      <Switch>
        <Route path="/portfolio" exact component={PortfolioGallery} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/notice" exact component={Notice} />
        <Route path="/getPortfolio" exact component={GetPortfolio} />
        <Redirect to="/portfolio"></Redirect>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
