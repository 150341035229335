import React from "react";
import "./MoreAboutMe.css";
import meImg from "../../images/me.jpg";

import LazyLoad from "react-lazyload";
import { LazyLoadPlaceholder } from "../../components";

function MoreAboutMe() {
  return (
    <div className="Container marginTop marginBottom">
      <div className="about-wrapper">
        <div className="leftColumn50">
          <div className="about-me-quote">
            <p>'Forever seeking balance in the in-between.'</p>
            <p>- I said that</p>
          </div>
          <div className="about-me-text">
            <p>...welcome to the website of Christian. Born in the 80s, he has built an impressive career. As a talented photographer and musician, he has made a name for himself in both industries. With a passion for photography that has been with him for years, he has worked with big companies such as Scherer Group, Rowe Oil, Opticon, Salesfever, and different auctioneers such as Auktionshaus Wiesbaden. Based in Lübeck, Hamburg, and Frankfurt/M, Christian specializes in advertising, people & lifestyle, and editorial photography.</p>
            <br/>
            <p>As a songwriter and bandleader, Christian has written six albums that were published under different record labels, including Bodog Music, Napalm Records, Rookie’s & Kings and 'Junge Dein Label', which he co-founded. Impressively, five of his records made it onto the Media Control charts, with rankings of 3, 10, 12, 29, and 52. Christian's music has also been featured in the 2008 TV Total Stock Car Challenge soundtrack with the song 'Adrenalin' by his band, Serum 114. We invite you to explore Christian's portfolio and see for yourself the talent and skill he brings to both his photography and music.</p>
          </div>
        </div>
        <div className="rightColumn50">
          <LazyLoad placeholder={<LazyLoadPlaceholder />} once offset={0}>
            <img className="meImage opacityFadeEffect" src={meImg}></img>
          </LazyLoad>
        </div>
      </div>
    </div>
  );
}

export default MoreAboutMe;
