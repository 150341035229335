import React, { useEffect, useState } from "react";
import { Container } from "../../globalStyles";
import "./ImageGallery.css";
import LazyLoad from "react-lazyload";
import { LazyLoadPlaceholder } from "../../components";
import CloseIcon from "@material-ui/icons/Close";

import { useStore } from "../../store";

import { useHistory } from "react-router-dom";
import { AcUnitTwoTone } from "@material-ui/icons";

function ImageGallery({ imageCategoryGallery }) {
  const { imageGalleryFilter } = useStore();
  const history = useHistory();
  var imagaGalleryData = setImageGalleryByCategory();

  useEffect(() => {
    imagaGalleryData = setImageGalleryByCategory();
  }, [imageGalleryFilter]);

  function setImageGalleryByCategory() {
    let curImagaGalleryData = imageCategoryGallery.filter((item) => {
      return item.name == imageGalleryFilter;
    });
    return curImagaGalleryData;
  }

  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  function goToSubPortfolio(aSubCategory) {
    history.push("/getPortfolio?subcat=" + aSubCategory);
    
  }

  return (
    <>
      <div id="InfoSecImg">
        <div className="gallery">
          {imagaGalleryData &&
            imagaGalleryData[0] &&
            imagaGalleryData[0].images.map((item, index) => {
              return (
                <LazyLoad
                  key={index}
                  placeholder={<LazyLoadPlaceholder />}
                  once
                  offset={0}
                >
                  <div
                    className="pics-polaroid-wrapper"
                    onClick={() => goToSubPortfolio(item.param)}
                  >
                    <div className="pics" key={index}>
                      <img
                        src={item.imgSrc}
                        className="opacityFadeEffect imgGallery"
                        style={{ width: "100%" }}
                      />
                    </div>
                    {item.desc != "" && (
                      <div className="pics-polaroid-name">{item.desc}</div>
                    )}
                  </div>
                </LazyLoad>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default ImageGallery;
