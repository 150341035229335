import React from "react";
import "./SubPortfolioGallery.css";

import LazyLoad from "react-lazyload";
import { LazyLoadPlaceholder } from "../../components";


function SubPortfolioGallery({ subPortfolioGalleryImages }) {
  return (
    <>
      <div className="detailed-portfolio-images-margin">
        {subPortfolioGalleryImages.map((item, index) => {
          return (
            <LazyLoad key={index} placeholder={<LazyLoadPlaceholder />}>
              <div className="detailed-portfolio-images">
                <img
                  className="img-size opacityFadeEffect"
                  src={item.imgScr}
                ></img>
              </div>
            </LazyLoad>
          );
        })}
      </div>
    </>
  );
}

export default SubPortfolioGallery;
