import React, { Component } from "react";
import L from "leaflet";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./LeafletMap.css";
import "leaflet-providers";
import markerImg from "../../images/camera.svg";
import leaflet_map_img from "./leaflet_map_img.png"

const markerIcon = new L.Icon({
  iconUrl: markerImg,
  iconSize: [30, 25],
});

class LeafletMap extends Component {
  render() {
    return (
      /*<Map
        className="map"
        center={[53.861755, 10.694906]}
        zoom={18}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        />
        <Marker position={[53.861755, 10.694906]} icon={markerIcon}>
          <Popup>ESCHWEILER PHOTOGRAPHY</Popup>
        </Marker>
      </Map>*/
      <div class="mapImgWrapper">
        <img class="mapImg" src={leaflet_map_img}/>
      </div>
    );
  }
}
export default LeafletMap;
