import React from "react";
import {
  navBarParam,
  AboutImageSrc,
  SimpleHeaderParam,
  AboutMeParam,
} from "./Data";
import {
  Navbar,
  AboutImage,
  AboutMe,
  SimpleHeader,
  MoreAboutMe,
} from "../../components";

function About() {
  return (
    <>
      <Navbar {...navBarParam} />
      <AboutImage {...AboutImageSrc} />
      <SimpleHeader {...SimpleHeaderParam} />
      <AboutMe {...AboutMeParam} />
      <MoreAboutMe />
    </>
  );
}

export default About;
