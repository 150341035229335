import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div class="footer-basic">
        <footer>
          <div class="social">
            <a href="https://www.facebook.com/ESCHWEILER.images" target="_blank">
              <FaFacebook />
            </a>
            <a href="https://www.instagram.com/eschweilerphotography/?igshid=YmMyMTA2M2Y%3D" target="_blank">
              <FaInstagram />
            </a>
          </div>
          <ul class="list-inline">
            <li class="list-inline-item">
              <a href="/portfolio">PORTFOLIO</a>
            </li>
            <li class="list-inline-item">
              <a href="/about">ABOUT</a>
            </li>
            <li class="list-inline-item">
              <a href="/contact">CONTACT</a>
            </li>
            <li class="list-inline-item">
              <a href="/notice">IMPRINT</a>
            </li>
          </ul>
          <p class="copyright">ESCHWEILER PHOTOGRAPHY © {new Date().getFullYear() }</p>
        </footer>
      </div>
    </>
  );
}

export default Footer;
