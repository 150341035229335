import React, { useEffect, useState } from "react";
import "./ImageGalleryFilter.css";
import { useStore } from "../../store";

function ImageGalleryFilter({ imageCategoryGallery }) {
  const [activeFilter, setActiveFilter] = useState();
  const { imageGalleryFilter, setGlobalImageGalleryFilter } = useStore();

  useEffect(() => {
    setFilterActive(imageCategoryGallery[0].name);
  }, []);

  function setFilterActive(curFilter) {
    setActiveFilter(curFilter);
    setGlobalImageGalleryFilter(curFilter);
  }

  return (
    <div className="image-gallery-filter-area ">
      <div className="WideContainer flex-wrapper">
        <h2 className="image-gallery-filter-header">Portfolio</h2>
        <div className="image-gallery-actual-filter">
          <ul class="ui-list-style-none ui-center">
            {imageCategoryGallery.map((item, index) => {
              return (
                <li
                  key={index}
                  className={
                    activeFilter == item.name
                      ? "filter-list-inline-item no-textdeco"
                      : "filter-list-inline-item no-textdeco grayColor"
                  }
                  onClick={setFilterActive.bind(null, item.name)}
                  name={item.name}
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ImageGalleryFilter;
