// Image Gallery People
import bannerImage from "../../images/business/Commercials_and_Packshots/04_Auctioneers-Commercial-EschweilerPhotography_M1A6736.jpg";

import ig1 from "../../images/business/Commercials_and_Packshots/01_Auctioneers-Commercial-EschweilerPhotography_M1A6758.jpg";
import ig2 from "../../images/business/Commercials_and_Packshots/02_Auctioneers-Commercial-EschweilerPhotography_M1A0876.jpg";
import ig3 from "../../images/business/Commercials_and_Packshots/03_Auctioneers-Commercial-EschweilerPhotography_M1A9628.jpg";
import ig4 from "../../images/business/Commercials_and_Packshots/05_Auctioneers-Commercial-EschweilerPhotography_M1A6711.jpg";
import ig5 from "../../images/business/Commercials_and_Packshots/06_Auctioneers-Commercial-EschweilerPhotography_M1A0372.jpg";
import ig6 from "../../images/business/Commercials_and_Packshots/07_Auctioneers-Commercial-EschweilerPhotography_M1A6775.jpg";
import ig7 from "../../images/business/Commercials_and_Packshots/08_Business-ProductArchitecture-InteriorEschweilerPhotography_M1A7702_2_3.jpg";
import ig8 from "../../images/business/Commercials_and_Packshots/09_Business-ProductArchitecture-InteriorEschweilerPhotography_M1A7731.jpg";
import ig9 from "../../images/business/Commercials_and_Packshots/10_Business-ProductArchitecture-InteriorEschweilerPhotography_M1A7563_2.jpg";
import ig10 from "../../images/business/Commercials_and_Packshots/11_Business-ProductArchitecture-InteriorEschweilerPhotography_M1A7563_3_.jpg";
import ig11 from "../../images/business/Commercials_and_Packshots/12_Business-ProductArchitecture-InteriorEschweilerPhotography_M1A7563.jpg";
import ig12 from "../../images/business/Commercials_and_Packshots/13_Business-ProductArchitecture-InteriorEschweilerPhotography_M1A7581.jpg";
import ig13 from "../../images/business/Commercials_and_Packshots/14_MestMarzipan-Commercial-EschweilerPhotography_21A5908 Kopie.jpg";
import ig14 from "../../images/business/Commercials_and_Packshots/15_MestMarzipan-Commercial-EschweilerPhotography_21A9729.jpg";
import ig15 from "../../images/business/Commercials_and_Packshots/20_MestMarzipan-Commercial-EschweilerPhotography_21A0413.jpg";
import ig16 from "../../images/business/Commercials_and_Packshots/21_MestMarzipan-Commercial-EschweilerPhotography_21A0473.jpg";
import ig17 from "../../images/business/Commercials_and_Packshots/22_MestMarzipan-Commercial-EschweilerPhotography_21A0484.jpg";
import ig18 from "../../images/business/Commercials_and_Packshots/23_Business-ProductArchitecture-InteriorEschweilerPhotographybebe_young_care.jpg";
import ig19 from "../../images/business/Commercials_and_Packshots/24_Business-ProductArchitecture-InteriorEschweilerPhotographybebe_moon11_.jpg";

export const SubPortfolioGallery = {
  bannerImage: bannerImage,
  bannerHeading: "Commercials & Packshots",
  subPortfolioGalleryImages: [
    {
      imgScr: ig1,
    },
    {
      imgScr: ig2,
    },
    {
      imgScr: ig3,
    },
    {
      imgScr: ig4,
    },
    {
      imgScr: ig5,
    },
    {
      imgScr: ig6,
    },
    {
      imgScr: ig7,
    },
    {
      imgScr: ig8,
    },
    {
      imgScr: ig9,
    },
    {
      imgScr: ig10,
    },
    {
      imgScr: ig11,
    },
    {
      imgScr: ig12,
    },
    {
      imgScr: ig13,
    },
    {
      imgScr: ig14,
    },
    {
      imgScr: ig15,
    },
    {
      imgScr: ig16,
    },
    {
      imgScr: ig17,
    },
    {
      imgScr: ig18,
    },
    {
      imgScr: ig19,
    }
  ],
};
