import React, { useState, useEffect } from "react";
import "./ScrollIconAnimation.css";

function ScrollIconAnimation() {
  const [displayScrollIcon, setDisplayScrollIcon] = useState(true);

  const setDisplayScollIcon = () => {
    if (window.scrollY >= 80 || window.innerWidth <= 1000) {
      setDisplayScrollIcon(false);
    } else {
      setDisplayScrollIcon(true);
    }
  };

  window.addEventListener("scroll", setDisplayScollIcon);
  window.addEventListener("load", setDisplayScollIcon);
  window.addEventListener("resize", setDisplayScollIcon);

  return (
    <div
      class="icon-scroll"
      style={displayScrollIcon ? { display: "block" } : { display: "none" }}
    ></div>
  );
}

export default ScrollIconAnimation;
