import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import {
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
  MobileHeaderDesc,
} from "./Navbar.elements";

function Navbar({ transparent }) {
  const history = useHistory();
  const [click, setClick] = useState(false);
  const [navBar, setNavbar] = useState("");
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  window.addEventListener("resize", () => {
    changeNavBarOnMobile();
  });
  window.addEventListener("scroll", changeBackground);

  useEffect(() => {
    changeNavBarOnMobile();
  }, []);

  function changeBackground() {
    if (window.scrollY >= 80) {
      setNavbar("active");
    } else if (window.innerWidth > 1000) {
      setNavbar("");
    }
  }

  function changeNavBarOnMobile() {
    if (window.innerWidth <= 1000) {
      setNavbar("active");
    } else {
      changeBackground();
    }
  }

  function navigateToHome() {
    history.push("/portfolio");
  }

  return (
    <>
      <Nav active={navBar} transparent={transparent}>
        <NavbarContainer>
          <MobileHeaderDesc onClick={navigateToHome}>
            ESCHWEILER PHOTOGRAPHY
          </MobileHeaderDesc>
          <MobileIcon onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <NavMenu onClick={handleClick} click={click}>
            <NavItem active={navBar} transparent={transparent}>
              <NavLink
                to="/portfolio"
                active={navBar}
                onClick={closeMobileMenu}
                transparent={transparent}
              >
                PORTFOLIO
              </NavLink>
            </NavItem>
            <NavItem active={navBar} transparent={transparent}>
              <NavLink
                to="/about"
                active={navBar}
                onClick={closeMobileMenu}
                transparent={transparent}
              >
                ABOUT
              </NavLink>
            </NavItem>
            <NavItem active={navBar} transparent={transparent}>
              <NavLink
                to="/contact"
                active={navBar}
                onClick={closeMobileMenu}
                transparent={transparent}
              >
                CONTACT
              </NavLink>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
