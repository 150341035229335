// Image Gallery People
import bannerImage from "../../images/business/Architecture_and_Interior/02_Eschweiler_photographer_Esche_Serum114_Architecture_Interieur_3.jpg";

import ig1 from "../../images/business/Architecture_and_Interior/01_Eschweiler_photographer_Esche_Serum114_Architecture_Interieur_1.jpg";
import ig2 from "../../images/business/Architecture_and_Interior/03_Eschweiler_photographer_Esche_Serum114_Architecture_Interieur_2.jpg";
import ig3 from "../../images/business/Architecture_and_Interior/04_Livingroom-Interior-Architecture-EschweilerPhotography_M1A9723.jpg";
import ig4 from "../../images/business/Architecture_and_Interior/05_Livingroom-Interior-Architecture-EschweilerPhotography_M1A9690.jpg";
import ig5 from "../../images/business/Architecture_and_Interior/06_Livingroom-Interior-Architecture-EschweilerPhotography_21A0003-HDR2.jpg";
import ig6 from "../../images/business/Architecture_and_Interior/07_Livingroom-Interior-Architecture-EschweilerPhotography07.jpg";
import ig7 from "../../images/business/Architecture_and_Interior/08_Livingroom-Interior-Architecture-EschweilerPhotography04.jpg";
import ig8 from "../../images/business/Architecture_and_Interior/09_Livingroom-Interior-Architecture-EschweilerPhotography01.jpg";
import ig9 from "../../images/business/Architecture_and_Interior/10_Livingroom-Interior-Architecture-EschweilerPhotography02.jpg";
import ig10 from "../../images/business/Architecture_and_Interior/Livingroom-Interior-Architecture-EschweilerPhotography_21A0021-HDR Kopie.jpg";
import ig11 from "../../images/business/Architecture_and_Interior/Livingroom-Interior-Architecture-EschweilerPhotography_MG_093022_AS.jpg";

export const SubPortfolioGallery = {
  bannerImage: bannerImage,
  bannerHeading: "Architecture & Interior",
  subPortfolioGalleryImages: [
    {
      imgScr: ig1,
    },
    {
      imgScr: ig2,
    },
    {
      imgScr: ig3,
    },
    {
      imgScr: ig4,
    },
    {
      imgScr: ig5,
    },
    {
      imgScr: ig6,
    },
    {
      imgScr: ig7,
    },
    {
      imgScr: ig8,
    },
    {
      imgScr: ig9,
    },
    {
      imgScr: ig10,
    },
    {
      imgScr: ig11,
    }
  ],
};
