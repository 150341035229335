import React from "react";
import "./ImprintBlockInfo.css";

function ImprintBlockInfo({ imprintHeader, paragraphs }) {
  return (
    <div className="Container">
      <div
        className="imprintHeader level0"
        style={{ marginTop: imprintHeader.margineTop }}
      >
        {imprintHeader.headline}
      </div>
      {paragraphs.map((item, index) => {
        return (
          <div
            className="imprintParagraph"
            style={{ marginTop: item.margineTop }}
          >
            {item.line}
          </div>
        );
      })}
    </div>
  );
}

export default ImprintBlockInfo;
