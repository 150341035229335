import React from "react";
import "./AboutImage.css";

import LazyLoad from "react-lazyload";
import { LazyLoadPlaceholder } from "../../components";

function AboutImage({ imageSrc }) {
  return (
    <div>
      <LazyLoad placeholder={<LazyLoadPlaceholder />} once offset={100}>
        <img className="aboutImage opacityFadeEffect" src={imageSrc}></img>
      </LazyLoad>
    </div>
  );
}

export default AboutImage;
