// Image Gallery People
import bannerImage from "../../images/business/Rowe_Oil/02_RoweOil-Commercial-EschweilerPhotography_M1A9958-3_3_flat_Shadows.jpg";

import ig1 from "../../images/business/Rowe_Oil/01_RoweOil-Commercial-EschweilerPhotography_M1A0288_flat_Shadows.jpg";
import ig2 from "../../images/business/Rowe_Oil/03_RoweOil-Commercial-EschweilerPhotography_M1A0173_1_flat_Shadows.jpg";
import ig3 from "../../images/business/Rowe_Oil/04_RoweOil-Commercial-EschweilerPhotography_M1A0150_flat_Shadows.jpg";
import ig4 from "../../images/business/Rowe_Oil/05_RoweOil-Commercial-EschweilerPhotography_M1A0246_flat_Shadows.jpg";

export const SubPortfolioGallery = {
  bannerImage: bannerImage,
  bannerHeading: "Rowe Oil",
  subPortfolioGalleryImages: [
    {
      imgScr: ig1,
    },
    {
      imgScr: ig2,
    },
    {
      imgScr: ig3,
    },
    {
      imgScr: ig4,
    }
  ],
};
